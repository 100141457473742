<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <organization-selector
        :organization="customer.organization.id"
        :filterable="true"
        :showAll="false"
        @organizationChanged="
          (organizationId) => {
            customer.organization.id = organizationId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="`${$t('COMMON.LOCATION')}`"
      :placeholder="$t('COMMON.LOCATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
    >
      <locations-selector
        :locations="customer.allowedLocations"
        :filterable="true"
        :showAll="false"
        :multiple="true"
        :organization="customer.organization.id"
        @locationsChanged="
          (locations) => {
            customer.allowedLocations = locations;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.location" />

    <base-input
      :label="`${$t('COMMON.TYPE')} (*)`"
      :placeholder="$t('COMMON.TYPE')"
      @change="
        (value) => {
          onChangeCustomerType(value);
        }
      "
    >
      <el-select
        name="Type"
        v-model="customer.customer_type"
        @change="
          () => {
            onFormChanged();
          }
        "
      >
        <el-option
          value="INDIVIDUAL"
          :label="$t('CUSTOMERS.CUSTOMER_TYPE_INDIVIDUAL')"
        />
        <el-option
          value="COMPANY"
          :label="$t('CUSTOMERS.CUSTOMER_TYPE_COMPANY')"
        />
      </el-select>
    </base-input>
    <validation-error :errors="apiValidationErrors.customer_type" />

    <div class="row" v-if="customer.customer_type === CUSTOMER_TYPE_INDIVIDUAL">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.FIRSTNAME')} (*)`"
          v-model="customer.firstname"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.firstname" />
      </div>
      <div class="col">
        <base-input
          :label="`${$t('COMMON.LASTNAME')} (*)`"
          v-model="customer.lastname"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.lastname" />
      </div>
    </div>

    <div class="row" v-if="customer.customer_type === CUSTOMER_TYPE_COMPANY">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.COMPANY_NAME')} (*)`"
          v-model="customer.company_name"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.company_name" />
      </div>
    </div>

    <base-input
      :label="`${$t('COMMON.EMAIL')} (*)`"
      v-model="customer.email"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.email" />

    <!-- Phone number -->
    <div class="row">
      <div class="col-2">
        <base-input :label="`${$t('COMMON.TYPE')}`">
          <el-select
            :label="$t('COMMON.TYPE')"
            :placeholder="$t('COMMON.TYPE')"
            v-model="customer.phone_type"
            @change="
              (type) => {
                customer.phone_type = type;
                onFormChanged();
              }
            "
          >
            <el-option
              v-for="(value, key) in phoneTypesOptions"
              :key="key"
              :value="value"
              :label="$t(`COMMON.PHONE_TYPE_${value}`)"
            />
          </el-select>
        </base-input>
      </div>
      <div class="col-7">
        <base-input :label="`${$t('COMMON.PHONE')} (*)`">
          <phone-number-input
            :phoneNumber="customer.phone"
            @phoneNumberChanged="
              (phone) => {
                customer.phone = phone;
                onFormChanged();
              }
            "
            :required="true"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.phone" />
      </div>
      <div class="col-3">
        <base-input
          :label="`${$t('COMMON.EXTENSION')}`"
          v-model="customer.phone_extension"
          @change="
            () => {
              onFormChanged();
            }
          "
          :inputClasses="'extension-input'"
        />
        <validation-error :errors="apiValidationErrors.phone_extension" />
      </div>
    </div>

    <!-- Other phone numbers -->
    <base-input :label="`${$t('COMMON.OTHER_PHONES')}`">
      <phone-numbers-selector
        :phoneNumbers="customer.other_phones"
        @phoneNumbersChanged="
          (phoneNumbers) => {
            customer.other_phones = phoneNumbers;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.other_phones" />

    <div class="form-wrapper full mb-3">
      <addresses-selector
        @addressInputChangeChanged="
          (values) => {
            customer = {
              ...customer,
              ...values,
            };
            onFormChanged();
          }
        "
      />
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.COUNTRY')} (*)`"
          :placeholder="$t('COMMON.COUNTRY')"
        >
          <country-selector
            :country="customer.country"
            :filterable="true"
            :showAll="false"
            @countryChanged="
              (country) => {
                customer.country = country;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.country" />
      </div>
      <div class="col">
        <base-input
          :label="`${$t('COMMON.STATE')} (*)`"
          :placeholder="$t('COMMON.STATE')"
        >
          <state-selector
            :country="customer.country"
            :state="customer.state"
            :filterable="true"
            :showAll="false"
            @stateChanged="
              (state) => {
                customer.state = state;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.state" />
      </div>
      <div class="col">
        <base-input
          :label="`${$t('COMMON.CITY')} (*)`"
          v-model="customer.city"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.city" />
      </div>
      <div class="col">
        <base-input
          :label="`${$t('COMMON.ZIPCODE')} (*)`"
          v-model="customer.zipcode"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.zipcode" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.ADDRESS')} (*)`"
          v-model="customer.address"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.address" />
      </div>
    </div>

    <!-- Tags -->
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.TAGS')}`"
          :placeholder="`${$t('COMMON.TAGS')}`"
        >
          <tags-selector
            :tags="customer.tags"
            @tagsChanged="
              (tags) => {
                customer.tags = tags;
                onFormChanged();
              }
            "
            :disabled="!customer.organization.id"
            :organization="customer.organization.id"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.tags" />
      </div>
    </div>

    <h2>{{ $t("COMMON.BILLING_ADDRESS") }}</h2>

    <base-checkbox
      v-model="billingAddressSameAsCustomer"
      class="mb-3"
      @input="applyCustomerDataToBilling"
    >
      <span class="form-control-label">
        {{ $t("CUSTOMERS.BILLING_INFORMATIONS_SAME_AS_CUSTOMER") }}
      </span>
    </base-checkbox>

    <billing-informations-form
      v-if="!billingAddressSameAsCustomer"
      :billingInformationsData="customer"
      :formErrors="formErrors"
      :type="customer.customer_type"
      @onChangeBillingInformations="billingInformationsChanged"
    />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          customer.id
            ? $t("CUSTOMERS.EDIT_CUSTOMER")
            : $t("CUSTOMERS.ADD_CUSTOMER")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import { phoneTypesOptions } from "@/constants/common";
import formMixin from "@/mixins/form-mixin";
import {
  CUSTOMER_TYPE_INDIVIDUAL,
  CUSTOMER_TYPE_COMPANY,
} from "@/constants/customers";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import CountrySelector from "@/components/CountrySelector.vue";
import StateSelector from "@/components/StateSelector.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import BillingInformationsForm from "@/components/BillingInformationsForm.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import TagsSelector from "@/components/TagsSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import PhoneNumbersSelector from "@/components/PhoneNumbersSelector.vue";
import AddressesSelector from "@/components/AddressesSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    CountrySelector,
    StateSelector,
    OrganizationSelector,
    BillingInformationsForm,
    AddressesSelector,
    PhoneNumberInput,
    TagsSelector,
    LocationsSelector,
    PhoneNumbersSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["customerData", "formErrors", "loading"],

  data() {
    let customerData = { ...this.customerData };
    customerData = this.$fillUserOrganizationData(customerData);
    return {
      CUSTOMER_TYPE_COMPANY: CUSTOMER_TYPE_COMPANY,
      CUSTOMER_TYPE_INDIVIDUAL: CUSTOMER_TYPE_INDIVIDUAL,
      customer: customerData,
      billingAddressSameAsCustomer: false,
      phoneTypesOptions,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let customerData = cloneDeep(this.customer);
      if (this.customer.customer_type === CUSTOMER_TYPE_INDIVIDUAL) {
        customerData.company_name = "N/A";
        customerData.billing_company_name = "N/A";
      } else if (this.customer.customer_type === CUSTOMER_TYPE_COMPANY) {
        customerData.firstname = "N/A";
        customerData.lastname = "N/A";
        customerData.billing_firstname = "N/A";
        customerData.billing_lastname = "N/A";
      }
      customerData = this.$fillUserOrganizationData(customerData);
      this.$emit("customerSubmitted", customerData);
    },

    billingInformationsChanged(billingInformations) {
      this.customer = { ...this.customer, ...billingInformations };
      this.onFormChanged();
    },

    onFormChanged() {
      if (this.billingAddressSameAsCustomer) {
        this.customer.billing_entity_type = this.customer.customer_type;
        this.customer.billing_company_name = this.customer.company_name;
        this.customer.billing_firstname = this.customer.firstname;
        this.customer.billing_lastname = this.customer.lastname;
        this.customer.billing_country = this.customer.country;
        this.customer.billing_state = this.customer.state;
        this.customer.billing_city = this.customer.city;
        this.customer.billing_zipcode = this.customer.zipcode;
        this.customer.billing_address = this.customer.address;
        this.customer.billing_email = this.customer.email;
      }
      this.$emit("formChanged");
    },

    onChangeCustomerType(value) {
      this.customer.billing_entity_type = value;
      if (value === CUSTOMER_TYPE_INDIVIDUAL) {
        this.customer.company_name = "N/A";
        this.customer.billing_company_name = "N/A";
      } else if (value === CUSTOMER_TYPE_COMPANY) {
        this.customer.firstname = "N/A";
        this.customer.lastname = "N/A";
        this.customer.billing_firstname = "N/A";
        this.customer.billing_lastname = "N/A";
      }
      onFormChanged();
    },

    applyCustomerDataToBilling(value) {
      this.onFormChanged();
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    customerData(customerData) {
      if (customerData) {
        this.customer = {
          ...this.customer,
          ...cloneDeep(customerData),
        };
      }
    },
  },
};
</script>
